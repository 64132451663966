import React from "react"
import { graphql } from "gatsby"
import { Card as CardComponent } from "theme-ui"
import { Stack, Main, Sidebar } from "@layout"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import Sticky from "@components/Sticky"
import CardList from "../components/Blog/CardList"

import AuthorCompact from "../components/BlogDetails/AuthorCompact"
import PostHead from "../components/BlogDetails/Post.Head"
import PostImage from "../components/BlogDetails/Post.Image"
import PostBody from "../components/BlogDetails/Post.Body"
import PostComments from "../components/BlogDetails/Post.Comments"
import "./animation.css"

const styles = {
  wrapImage: {
    position: `relative`,
  },
}

export const PublicationsAndAuthorQuery = graphql`
  query PublicationsAndAuthor($id: String!) {
    publication: allMarkdownRemark(filter: { id: { eq: $id } }) {
      nodes {
        frontmatter {
          templateKey
          name_author
          date(formatString: "MMMM DD, YYYY")
          excerpt
          title
          thumbnail
        }
        fields {
          slug
        }
        id
        html
      }
    }
    author: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about" } } }
    ) {
      nodes {
        frontmatter {
          templateKey
          date(formatString: "MMMM DD, YYYY")
          avatar
          name
          role
        }
        fields {
          slug
        }
        id
      }
    }
    publications: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "publication" } } }
    ) {
      nodes {
        frontmatter {
          name_author
          date(formatString: "MMMM DD, YYYY")
          title
        }
        fields {
          slug
        }
        id
      }
    }
  }
`

const BlogDetails = ({
  data,
  pageContext: { services = {}, siteUrl } = {},
}) => {
  const publicationData = data.publication.nodes
  const authorsData = data.author.nodes
  const publicationsData = data.publications.nodes
  const nameAuthor = publicationData[0].frontmatter.name_author
  const authorData = authorsData.find(
    dataItem => dataItem.fields.slug === `/leadership/${nameAuthor}`
  )
  const publicationsAuthor = publicationsData.filter(
    dataItem =>
      dataItem.frontmatter.name_author === nameAuthor &&
      dataItem.id !== publicationData[0].id
  ).map(dataItem => {
    const {frontmatter: {date, title}, fields: {slug}, id} = dataItem
    return {
      date,
      slug,
      id,
      title,
      author: {
        slug: authorData.fields.slug,
        name: authorData.frontmatter.name,
        id: authorData.id,
      },
    }
  })

  const {
    id,
    html,
    frontmatter: { name_author, date, excerpt, title, thumbnail },
    fields: { slug },
  } = publicationData[0]

  const {
    frontmatter: { name, avatar, role },
  } = authorData

  const post = {
    name_author,
    body: html,
    date,
    slug,
    excerpt,
    title,
    thumbnail,
    id,
    author: {
      slug: authorData.fields.slug,
      title: role,
      name,
      id: authorData.id,
      avatar,
    }
  }

  return (
    <>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider space={3} />
      <div className="animateInDown">
        <PostHead {...post} />
      </div>
      <Divider space={3} />
      <div className="animateInUp">
        <Main>
          <CardComponent variant="paper" style={styles.wrapImage}>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            {services.disqus && <PostComments {...post} />}
          </CardComponent>
          <Divider space={3} />
        </Main>
        <Sidebar>
          <AuthorCompact author={post.author} omitTitle bgShow={true} />
          <Divider space={3} />
          <Sticky>
            <CardList
              title="Related Posts"
              nodes={[...publicationsAuthor]}
              variant="horizontal-aside"
              limit={6}
              omitMedia
              omitCategory
              distinct
              aside
            />
          </Sticky>
        </Sidebar>
      </div>
      <Divider space={3} />
    </>
  )
}

export default BlogDetails